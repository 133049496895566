var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pr-12",attrs:{"cols":"auto"}},[_c('h1',[_vm._v("Breeder Stats")])]),_c('v-col',{staticClass:"pl-12",attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"label":"Search Breeders","outlined":"","dense":"","hide-details":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-row',{attrs:{"align":"center","justify":"end","no-gutters":""}},[_c('v-btn',{attrs:{"depressed":"","light":"","to":{ name: 'module-paw-breeders' }}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-arrow-left")]),_vm._v(" Back to Breeder list ")],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.customers,"items-per-page":-1,"footer-props":_vm.footerProps,"no-data-text":"No breeders found"},scopedSlots:_vm._u([{key:"item.full_name",fn:function({ item }){return [_c('router-link',{attrs:{"to":{
                name: 'module-paw-breeders-individual',
                params: { customerId: item.customer_id },
              }}},[_vm._v(_vm._s(item.customer.full_name))])]}},{key:"item.coupons_used",fn:function({ item }){return [_vm._v(" "+_vm._s(item.used_codes.length)+" ")]}},{key:"item.total_credits",fn:function({ item }){return [_vm._v(" £"+_vm._s(item.lifetime_credit)+" ")]}},{key:"item.coupons_used_percent",fn:function({ item }){return [(item.codes.length != 0)?_c('div',[_vm._v(" "+_vm._s(_vm.changeToFloat( (item.used_codes.length / item.codes.length) * 100 ))+"% ")]):_c('div',[_vm._v("0.00%")])]}},{key:"item.retained_min_3",fn:function({ item }){return [(
                item.deliveries_test.length != 0 &&
                item.used_codes.length != 0
              )?_c('div',[_vm._v(" "+_vm._s(_vm.changeToFloat( (item.deliveries_test.length / item.used_codes.length) * 100 ))+"% ")]):_c('div',[_vm._v("0.00%")])]}},{key:"item.actions",fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"x-small":"","depressed":"","color":"green lighten-4 green--text","to":{
                    name: 'module-paw-breeders-individual',
                    params: { customerId: item.customer_id },
                  }}},on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("View Customer")])])]}}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }